const us = [
    {
        image: "https://websitedemos.net/blingg-jewelry-store-04/wp-content/uploads/sites/1119/2022/08/icon-04.png",
        heading: "BIG DISCOUNTS",
        desc: "Integer euismod blandit nunc sit amet sollicitudin. Fusce quis orci viverra, cursus justo.",
    },
    {
        image: "https://websitedemos.net/blingg-jewelry-store-04/wp-content/uploads/sites/1119/2022/08/icon-01.png",
        heading: "FREE SHIPPING",
        desc: "Integer euismod blandit nunc sit amet sollicitudin. Fusce quis orci viverra, cursus justo.",
    },
    {
        image: "https://websitedemos.net/blingg-jewelry-store-04/wp-content/uploads/sites/1119/2022/08/icon-02.png",
        heading: "SECURE PAYMENTS",
        desc: "Integer euismod blandit nunc sit amet sollicitudin. Fusce quis orci viverra, cursus justo.",
    },
    {
        image: "https://websitedemos.net/blingg-jewelry-store-04/wp-content/uploads/sites/1119/2022/08/icon-03.png",
        heading: "ORDER TRACKING",
        desc: "Integer euismod blandit nunc sit amet sollicitudin. Fusce quis orci viverra, cursus justo.",
    },
]

export default us;
